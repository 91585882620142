import React, { useCallback } from 'react';

import Button from '~/components/Button';
import { EndingSectionDTO } from '~/pages/about-us/types';
import { AnalyticsService } from '~/services/Analytics';

import styles from './Ending.module.scss';

type Props = {
  data: EndingSectionDTO;
};

const Ending: React.FC<Props> = ({ data }) => {
  const onButtonClick = useCallback(() => {
    AnalyticsService.track({
      category: 'Estimate Project Button',
      action: 'CTA Section Click',
      label: `From ${window?.location.pathname}`,
    });
  }, []);

  return (
    <section className={styles.root}>
      <div className={styles.content}>
        <h3 className={styles.title}>{data.title}</h3>
        <Button
          as={{
            tag: 'Link',
            to: data.link,
          }}
          className={styles.button}
          onClick={onButtonClick}
        >
          {data.buttonLabel}
        </Button>
      </div>
      <img
        className={styles.image}
        srcSet={data.image.src}
        alt={data.image.alt}
        role='presentation'
      />
    </section>
  );
};

export default Ending;
