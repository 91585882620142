import { ManagementTeamSectionDTO } from '~/pages/about-us/types';

import joOverlinePhoto from './assets/jo-overline-photo.jpg';
import marcinMincerPhoto from './assets/marcin-mincer-photo.jpg';
import tomekKopczukPhoto from './assets/tomek-kopczuk-photo.jpg';
import jenniferLoftusPhoto from './assets/jennifer-loftus-photo.jpg';
import mandyEdmundPhoto from './assets/mandy-edmund-photo.jpg';

const managementTeamSection: ManagementTeamSectionDTO = {
  title: 'Meet our Management Team',
  items: [
    {
      name: 'Jo Overline',
      position: 'Co-Founder, CEO, SwingDev',
      linkedinProfileUrl: 'https://www.linkedin.com/in/overline/',
      photo: {
        alt: 'Jo Overline Photo',
        src: joOverlinePhoto,
      },
    },
    {
      name: 'Marcin Mincer',
      position: 'Co-Founder, COO, SwingDev',
      linkedinProfileUrl: 'https://www.linkedin.com/in/marcinmincer/',
      photo: {
        alt: 'Marcin Mincer Photo',
        src: marcinMincerPhoto,
      },
    },
    {
      name: 'Tomek Kopczuk',
      position: 'Co-Founder, CTO, SwingDev',
      linkedinProfileUrl: 'https://www.linkedin.com/in/tomekkopczuk/',
      photo: {
        alt: 'Tomek Kopczuk Photo',
        src: tomekKopczukPhoto,
      },
    },
    {
      name: 'Jennifer Loftus',
      position: 'Founder, SwingSearch Co-founder, SwingDev',
      linkedinProfileUrl:
        'https://www.linkedin.com/in/jenniferloftusswingtalent/',
      photo: {
        alt: 'Jennifer Loftus Photo',
        src: jenniferLoftusPhoto,
      },
    },
    {
      name: 'Mandy Edmund',
      position: 'Managing Partner, SwingSearch Co‑Founder, Swingdev',
      linkedinProfileUrl: 'https://www.linkedin.com/in/mandy-edmund-9b80a916/',
      photo: {
        alt: 'Mandy Edmund Photo',
        src: mandyEdmundPhoto,
      },
    },
  ],
};

export default managementTeamSection;
