import { KeyFactsSectionDTO } from '~/pages/about-us/types';

const valuesSection: KeyFactsSectionDTO = {
  title: 'What Makes Us Different',
  items: [
    {
      title: 'A boutique approach',
      description:
        'We hand-pick projects we engage in – we want you, as a client, to always feel cared for and special, and our team to love your product as much as you do. We hire the best to work with the best.',
    },
    {
      title: 'No culture gap',
      description:
        'Our founders come both from Poland and the US – we know what to do to bridge the gap between different working cultures and make your experience a pleasure.',
    },
    {
      title: 'Business acumen',
      description:
        'By partnering closely with leading US VCs, we live in the startup environment. No matter if we are talking about engineering or business, we know what works, and what doesn’t.',
    },
    {
      title: 'Long-term efficiency',
      description:
        'Save yourself future trouble – you can’t afford to go with a cheaper team. Our engineers work faster and more efficiently than most. Moreover, their solutions minimize product maintenance costs.',
    },
  ],
};

export default valuesSection;
