import classNames from 'classnames';
import React, { useState } from 'react';

import { Video } from '~/components/Video';
import { VideoPreview } from '~/components/VideoPreview';
import useVideos from '~/hooks/useVideos';

import { ReactComponent as PlayIcon } from './assets/play.svg';
import styles from './HeroVideo.module.scss';
import { EntrySectionDTO } from '~/pages/about-us/types';

type Props = {
  data: EntrySectionDTO;
  onVideoShow?: () => void;
};

const HeroVideo: React.FC<Props> = ({ data, onVideoShow }) => {
  const [showVideoPreview, setShowVideoPreview] = useState(false);

  const handleVideoShow = () => {
    setShowVideoPreview(true);
    onVideoShow && onVideoShow();
  };

  const handleVideoClose = () => setShowVideoPreview(false);

  const { title, subtitle, videoUrl } = data;
  const { getVideo, getVideoPreview } = useVideos();
  const backgroundVideo = getVideo(data.backgroundVideo);
  const backgroundVideoPreview = getVideoPreview(data.backgroundVideoPreview);

  const classes = classNames(styles.root, styles.textLeft);
  const descriptionClasses = classNames(styles.description, styles.withVideo);

  return (
    <section className={classes}>
      {backgroundVideo ? (
        <Video
          video={backgroundVideo}
          videoPreview={backgroundVideoPreview}
          className={styles.video}
        />
      ) : null}

      <div className={styles.container}>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className={descriptionClasses}>
          <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
        </p>

        <button className={styles.play} onClick={handleVideoShow}>
          <PlayIcon className={styles.playIcon} />
          Watch Video
        </button>
      </div>

      <VideoPreview
        videoUrl={videoUrl}
        title={title}
        show={showVideoPreview}
        onClose={handleVideoClose}
      />
    </section>
  );
};

export default HeroVideo;
