import React from 'react';

import { ManagementTeamMemberDTO } from '../../types';
import { ReactComponent as LinkedInIcon } from '~/assets/icons-social-media/linkedin.svg';

import styles from './MemberEntry.module.scss';

type Props = {
  data: ManagementTeamMemberDTO;
};

const MemberEntry: React.FC<Props> = ({ data }) => {
  return (
    <article className={styles.root}>
      <div className={styles.photoContainer}>
        <picture>
          <img
            className={styles.photo}
            src={data.photo.src}
            alt={data.photo.alt}
            role='presentation'
          />
        </picture>
        {data.linkedinProfileUrl ? (
          <a
            className={styles.link}
            href={data.linkedinProfileUrl}
            rel='noopener noreferrer'
            target='_blank'
            role='button'
          >
            <LinkedInIcon className={styles.icon} />
          </a>
        ) : null}
      </div>

      <h3 className={styles.name}>{data.name}</h3>
      <p className={styles.position}>{data.position}</p>
    </article>
  );
};

export default MemberEntry;
