import React, { useCallback } from 'react';

import aboutUs from '~/data/about-us';
import { AnalyticsService } from '~/services/Analytics';

import Layout from '~/components/Layout';
import KeyFacts from './components/KeyFacts';
import Values from './components/Values';
import Traits from './components/Traits';
import ManagementTeam from './components/ManagementTeam';
import Ending from './components/Ending';
import HeroVideo from '~/components/HeroVideo';

const AboutUs = () => {
  const {
    metadata,
    entrySection,
    keyFactsSection,
    valuesSection,
    traitsSection,
    managementTeamSection,
    endingSection,
  } = aboutUs;

  const handleVideoShow = useCallback(() => {
    AnalyticsService.track({
      category: 'Videos',
      action: 'Play',
      label: 'About Us Video',
    });
  }, []);

  return (
    <Layout
      pageTitle={metadata.pageTitle}
      metaDescription={metadata.metaDescription}
    >
      <HeroVideo onVideoShow={handleVideoShow} data={entrySection} />
      <KeyFacts data={keyFactsSection} />
      <Values data={valuesSection} />
      <Traits data={traitsSection} />
      <ManagementTeam data={managementTeamSection} />
      <Ending data={endingSection} />
    </Layout>
  );
};

export default AboutUs;
