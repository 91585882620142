import { EntrySectionDTO } from '~/pages/about-us/types';
import backgroundVideo from './assets/background-video.mp4';
import backgroundVideoPreview from './assets/background-video-preview.png';

const entrySection: EntrySectionDTO = {
  title: 'We Help Companies Grow',
  subtitle:
    '<strong>We only work on things we believe in</strong> – sharing your excitement and fully committing to the project is our recipe for extraordinary results.',
  videoUrl: 'https://www.youtube-nocookie.com/embed/MJff60VB91Y',
  backgroundVideo,
  backgroundVideoPreview,
};

export default entrySection;
