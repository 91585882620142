import React, { RefObject, useCallback } from 'react';

import MemberEntry from './MemberEntry';
import { ManagementTeamSectionDTO } from '../../types';
import { Breakpoint } from '~/constants';
import Carousel, { SlickSlider, CarouselSettings } from '~/components/Carousel';

import styles from './ManagementTeam.module.scss';

const carouselSettings: CarouselSettings = {
  className: styles.slider,
  rows: 1,
  infinite: false,
  slidesToShow: 5,
  arrows: false,
  draggable: false,
  responsive: [
    {
      breakpoint: Breakpoint.SMALL - 1, // Subtract one to display 3 entries on Ipad
      settings: {
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        draggable: true,
        variableWidth: true,
      },
    },
    {
      breakpoint: Breakpoint.MEDIUM,
      settings: {
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        draggable: true,
      },
    },
    {
      breakpoint: Breakpoint.LARGE,
      settings: {
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        draggable: true,
      },
    },
  ],
};

type Props = {
  data: ManagementTeamSectionDTO;
};

const ManagementTeam: React.FC<Props> = ({ data }) => {
  const { title, items } = data;
  const slider: RefObject<SlickSlider> = React.createRef();

  const onSwipe = useCallback(() => {
    if (slider && slider.current) {
      // @see https://github.com/akiran/react-slick/issues/1298
      // @ts-ignore Current typings don't support "innerSlider" yet.
      slider.current.innerSlider.clickable = true;
    }
  }, [slider]);

  return (
    <section className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <Carousel
        key='management-slider'
        forwardRef={slider}
        onSwipe={onSwipe}
        {...carouselSettings}
      >
        {items.map((item) => (
          <MemberEntry data={item} key={item.name} />
        ))}
      </Carousel>
    </section>
  );
};

export default ManagementTeam;
