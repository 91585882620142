import { TraitsSectionDTO } from '~/pages/about-us/types';
import ar3dTeamIcon from './assets/3d-ar-team-icon.svg';
import qaTestingTeamIcon from './assets/qa-testing-team-icon.svg';
import topSoftwareEngineersIcon from './assets/top-software-engineers-icon.svg';
import worldClassDesignTeamIcon from './assets/world-class-design-team-icon.svg';

const traitsSection: TraitsSectionDTO = {
  title: 'Everything you need in one place',
  description:
    'We are not just software engineers – SwingDev also offers top-notch designers, testers with an eye for detail, and business consultancy. These are all the key components to make your product a success, from napkin drawings to a seamless launch.',
  items: [
    {
      name: 'Top Software Engineers',
      image: {
        src: topSoftwareEngineersIcon,
        alt: 'Top Software Engineers Icon',
      },
    },
    {
      name: 'World-class Design team',
      image: {
        src: worldClassDesignTeamIcon,
        alt: 'World-class Design team icon',
      },
    },
    {
      name: 'QA / Testing Team',
      image: {
        src: qaTestingTeamIcon,
        alt: 'QA / Testing Team Icon',
      },
    },
    {
      name: '3D / AR Team',
      image: {
        src: ar3dTeamIcon,
        alt: '3D / AR Team Icon',
      },
    },
  ],
};

export default traitsSection;
