import React from 'react';
import chunk from 'lodash/chunk';

import { TraitsSectionDTO, TraitDTO } from '~/pages/about-us/types';

import styles from './Traits.module.scss';

const renderTrait = (trait: TraitDTO, index: number) => (
  <div className={styles.trait} key={index}>
    <figure className={styles.figure}>
      <img
        className={styles.image}
        src={trait.image.src}
        alt={trait.image.alt}
        role='presentation'
      />
    </figure>
    <div className={styles.name}>{trait.name}</div>
  </div>
);

const renderTraitsRow = (traits: TraitDTO[], index: number) => (
  <div className={styles.row} key={index}>
    {traits.map(renderTrait)}
  </div>
);

type Props = {
  data: TraitsSectionDTO;
};

const Traits: React.FC<Props> = ({ data }) => {
  const chunked = chunk(data.items, 2);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.text}>
            <div className={styles.heading}>{data.title}</div>
            <div className={styles.description}>{data.description}</div>
          </div>
          <div className={styles.traits}>{chunked.map(renderTraitsRow)}</div>
        </div>
      </div>
    </section>
  );
};

export default Traits;
