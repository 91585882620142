import { EndingSectionDTO } from '~/pages/about-us/types';
import routes from '~/routes';

import officeShot from './assets/office-shot.jpg';

const endingSection: EndingSectionDTO = {
  title: 'Got a project? Let’s estimate it.',
  link: routes.ESTIMATE_PROJECT,
  buttonLabel: 'Estimate your project',
  image: {
    src: officeShot,
    alt: 'Office Shot',
  },
};

export default endingSection;
