import React, { useCallback } from 'react';
import { ValuesSectionDTO, ValueDTO } from '~/pages/about-us/types';
import Image from '~/components/Image';

import styles from './Values.module.scss';

type Props = {
  data: ValuesSectionDTO;
};

const Values: React.FC<Props> = ({ data }) => {
  const renderValue = useCallback((value: ValueDTO, index: number) => {
    const image = value.image;
    return (
      <div className={styles.value} key={index}>
        {image.src ? (
          <figure className={styles.figure}>
            <Image className={styles.image} img={image.src} alt={image.alt} />
          </figure>
        ) : null}
        <div className={styles.content}>
          <h3 className={styles.title}>{value.title}</h3>
          <p className={styles.description}>{value.description}</p>
        </div>
      </div>
    );
  }, []);

  return (
    <section className={styles.root}>
      <h3 className={styles.headline}>{data.title}</h3>
      <div className={styles.values}>{data.items.map(renderValue)}</div>
    </section>
  );
};

export default Values;
