import { ValuesSectionDTO } from '~/pages/about-us/types';

import values1 from './assets/values-1.jpg';
import values2 from './assets/values-2.jpg';
import values3 from './assets/values-3.jpg';

const valuesSection: ValuesSectionDTO = {
  title:
    'At SwingDev, software development and consulting is an approachable, efficient service done with integrity.',
  items: [
    {
      title: 'Honesty, integrity, and transparency',
      description: `We approach every project as true partners in your success. We are not a team of "yes-men" who will let you follow the wrong path. Our product managers and engineers are trained in a culture that promotes 100% transparency. We are always on the lookout for our client's best interests – your success is our success.`,
      image: {
        src: values1,
        alt: 'People in a conference room',
      },
    },
    {
      title: 'Adjustable Process',
      description:
        'No matter if you are just starting up or represent a mature organization. We gained our experience through working on hundreds of projects, and can easily tune in to your process.',
      image: {
        src: values2,
        alt: 'A man playing drums',
      },
    },
    {
      title: 'Communication',
      description:
        'Our project managers participate in constant communications and leadership training to ensure that you have the best partner to work with you to find the best solutions for your specific challenge.',
      image: {
        src: values3,
        alt: 'Two men in the kitchen',
      },
    },
  ],
};

export default valuesSection;
