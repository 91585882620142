import { AboutUsDTO } from '~/pages/about-us/types';

import metadata from './metadata';
import entrySection from './entrySection';
import keyFactsSection from './keyFactsSection';
import valuesSection from './valuesSection';
import traitsSection from './traitsSection';
import managementTeamSection from './managementTeamSection';
import endingSection from './endingSection';

const aboutUs: AboutUsDTO = {
  metadata,
  entrySection,
  keyFactsSection,
  valuesSection,
  traitsSection,
  managementTeamSection,
  endingSection,
};

export default aboutUs;
