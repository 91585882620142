import React, { useCallback } from 'react';
import { KeyFactsSectionDTO, KeyFactDTO } from '~/pages/about-us/types';

import styles from './KeyFacts.module.scss';

type Props = {
  data: KeyFactsSectionDTO;
};

const KeyFacts: React.FC<Props> = ({ data }) => {
  const renderValue = useCallback((value: KeyFactDTO, index: number) => {
    const itemNumber = index + 1;

    return (
      <div className={styles.item} key={itemNumber}>
        <span className={styles.number}>{itemNumber}</span>
        <h5 className={styles.title}>{value.title}</h5>
        <p className={styles.description}>{value.description}</p>
      </div>
    );
  }, []);

  return (
    <section className={styles.root}>
      <h3 className={styles.subheadline}>Key Facts</h3>
      <h1 className={styles.headline}>{data.title}</h1>
      <div className={styles.items}>{data.items.map(renderValue)}</div>
    </section>
  );
};

export default KeyFacts;
